/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, StateCta, LandingPageCta, TwoColCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "if-youre-having-trouble-with-your-hearing-an-audiologist-can-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#if-youre-having-trouble-with-your-hearing-an-audiologist-can-help",
    "aria-label": "if youre having trouble with your hearing an audiologist can help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "If You’re Having Trouble with Your Hearing, an Audiologist Can Help!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What’s the first thing you do when you notice your eyesight is starting to get a little fuzzy? Chances are, you’d call your doctor or eye specialist to check your vision and order a pair of prescription lenses. It’s a quick and easy process to see better again. But did you know that most people wait years to see an audiologist or hearing specialist when they notice signs of ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), "?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, if an eye specialist can help to correct your vision, who do you turn to for hearing loss? The answer is an audiologist near you. These hearing care professionals are licensed and board-certified. They have all the resources to evaluate your hearing, recommend a solution, and get you on the path to a better quality of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-to-expect-when-you-see-an-audiologist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-to-expect-when-you-see-an-audiologist",
    "aria-label": "what to expect when you see an audiologist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What to Expect When You See an Audiologist"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/at-the-hearing-specialist-office.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are already noticing signs of hearing loss, the short answer is today. However, some people may not realize they’re having trouble hearing until someone else brings it to your attention. For instance, someone might make a comment about your constant need to have people repeat themselves. This can be frustrating not only for you but also for the individuals around you. You don’t have to be in denial if your loved ones are telling you that you may be experiencing hearing loss. The faster you act on your hearing loss, the easier it will be to enjoy a better quality of life. In addition to what the people around you are saying, here are some of the signs that may indicate you need to speak to an audiologist or hearing specialist.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "when-is-the-right-time-to-see-an-audiologist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#when-is-the-right-time-to-see-an-audiologist",
    "aria-label": "when is the right time to see an audiologist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "When Is the Right Time to See an Audiologist?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/invisible-hearing-aids.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Audiologists and hearing specialists are healthcare professionals with extensive education and training to perform hearing evaluations on adults and children. Not only can they help you with hearing loss, they can also check for any other underlying issues that may be affecting your ability to hear. For example, ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/tinnitus-causes-symptoms-possible-solutions/",
    className: "c-md-a"
  }, "ringing in the ear (tinnitus)"), " or ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax/",
    className: "c-md-a"
  }, "ear wax"), " build-up can make it difficult to hear clearly. An audiologist can perform a number of ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " to assess your situation and recommend a proper course of action.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we have a team of hearing aid experts, audiologists, and hearing specialists who can help you find the best solution. Our Partner Providers are licensed, board-certified professionals who are committed to improving your quality of life. We have over 2,000 offices nationwide, so it easy and convenient to visit one of our audiologists near you. During your visit, you can expect to get the best possible hearing care, a comprehensive evaluation, and expert advice on the ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "latest hearing aids"), "."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "People complain your TV volume is too loud"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "You have to ask people to repeat themselves constantly"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "You feel tired after a conversation"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "It’s difficult to hear in crowded and noisy rooms"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "You can hear but you have trouble understanding"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Your loved ones keep telling you to check your hearing"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you can relate to any of the scenarios noted above, then you should do something about your current hearing situation. In fact, many studies show that untreated hearing loss is linked to Alzheimer’s and Dementia. So, don’t wait until it’s too late."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-internet-and-your-hearing-health",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-internet-and-your-hearing-health",
    "aria-label": "the internet and your hearing health permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Internet and Your Hearing Health"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Internet is a wonderful place. Everything you need to know about anything is at your fingertips – literally! If you are like most Americans, when your knee starts hurting or your stomach starts making funny noises, you grab your smartphone, laptop, or desktop computer, open Google, and begin searching the web for answers. The Internet can provide a general idea of all the possible causes for what you are experiencing. But most of us know that it can lead us down a path of despair when the symptoms, according to Google, may be signs of a much bigger problem.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In terms of hearing loss, the Internet is a good place to start looking for solutions. After all, you are reading about audiologists and audiology on the hear.com website. But it shouldn’t stop there! You need to have a licensed audiologist or hearing specialist examine your ears, check your hearing, and recommend a solution that meets your individual needs. You can’t get that kind of personalized service with audiology online. ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "Online hearing tests"), " can serve as a small glimpse into what your problem might be, but it can’t give you the full picture."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "can-you-buy-hearing-aids-online-without-the-help-of-an-audiologist",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#can-you-buy-hearing-aids-online-without-the-help-of-an-audiologist",
    "aria-label": "can you buy hearing aids online without the help of an audiologist permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Can You Buy Hearing Aids Online Without the Help of an Audiologist?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/SIGNIA-Styletto.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Typically, what you would find online are hearing amplifiers. These devices are very inexpensive and have only have a few features. However, your hearing is as unique as your fingerprint, so a one-size-fits-all solution may not provide you with the clarity you need to hear well again. Therefore, only a qualified audiologist or hearing specialist can recommend the right hearing aids. In fact, purchasing any kind of amplification device from the Internet or a private seller may cost you more money in the end. Why? Because hearing aids need to be fitted and tailored according to your needs and lifestyle – a job only an audiologist or hearing specialist can do.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "we-have-over-2000-partner-providers-in-our-network",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#we-have-over-2000-partner-providers-in-our-network",
    "aria-label": "we have over 2000 partner providers in our network permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We Have over 2,000 Partner Providers in Our Network!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, our Partner Providers consist of the best audiologists and hearing specialists in the nation. Before they join our ", React.createElement(_components.a, {
    href: "/about-us/network/",
    className: "c-md-a"
  }, "network"), ", we screen and vet them to ensure they meet our standard of excellence. Choosing the right audiologist shouldn’t have to be time consuming. One of our hearing experts will pair you with the right Partner Provider in your area. Here are some of the advantages of choosing hear.com for your hearing needs:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Convenient locations"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dual-expert approach: your hear.com hearing expert and local Partner Provider"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "100% transparency throughout the entire process"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Comprehensive hearing evaluation and hearing aid fitting with a board-certified audiologist or hearing specialist"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "45 day risk-free trial of the latest hearing aids"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "100% money-back guarantee"), "\n"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "FIND AN AUDIOLOGIST",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-best-hearing-aids-recommendations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-best-hearing-aids-recommendations",
    "aria-label": "our best hearing aids recommendations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our Best Hearing Aids Recommendations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two of the best hearing aids on the market today include the Horizon Go IX and the Horizon Mini IX, both which are part of the exclusive Horizon line of hearing aids by hear.com. No matter if you have mild or severe hearing loss, one of these state-of-the-art devices is sure to work for you."), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Hearing Loss",
    ContentCopy2: "There are several types of hearing loss and many different cause.",
    Url2: "/hearing-loss/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
